import React from "react"
import { StoryGroup } from "components"
import { flatStoryGroup } from "utils/array-util"

const LinkMap = {}

const storiesNews2023 = [
  {
    title: "React Digital Garden 11.2023 (Nov) Last",
    postId: "19f51813a1bf",
  },
  {
    title: "React Digital Garden 10.2023 (Oct)",
    postId: "0cb41ea789d2",
  },
  {
    title: "React Digital Garden 09.2023 (Sep)",
    postId: "895aab65942d",
  },
  {
    title: "React Digital Garden 08.2023 (Aug)",
    postId: "855fd3c3bdd1",
  },
  {
    title: "React Digital Garden 07.2023 (Jul)",
    postId: "855fd3c3bdd1",
  },
  {
    title: "React Digital Garden 06.2023 (Jun)",
    postId: "e1ac8fe972c1",
  },
  {
    title: "React Digital Garden 05.2023 (May)",
    postId: "f54dae840f8",
  },
  {
    title: "React Digital Garden 04.2023 (Apr)",
    postId: "5be4aec691ae",
  },
  {
    title: "React Digital Garden 03.2023 (Mar)",
    postId: "2cc1d3c5e840",
  },
  {
    title: "React Digital Garden 02.2023 (Feb)",
    postId: "1e1d6306b43d",
  },
  {
    title: "React Digital Garden 01.2023 (Jan)",
    postId: "e933fb044787",
  },
]

const storiesNews2022 = [
  {
    title: "React Digital Garden 12.2022 (Dec)",
    postId: "55e31ff4d656",
  },
  {
    title: "React Digital Garden 11.2022 (Nov)",
    postId: "50750d1392ee",
  },
  {
    title: "React Digital Garden 10.2022 (Oct)",
    postId: "41262eddfeaf",
  },
  {
    title: "React Digital Garden 09.2022 (Sep)",
    postId: "670dd21f2a",
  },
  {
    title: "React Digital Garden 08.2022 (Agu)",
    postId: "20bc89980102",
  },
  {
    title: "React Digital Garden 07.2022 (July)",
    postId: "ac5b9afa8b80",
  },
  {
    title: "React Digital Garden 06.2022 (Jun)",
    postId: "b5acbf59398a",
  },
  {
    title: "React Digital Garden 05.2022 (May)",
    postId: "cfe62dc8f98",
  },

  {
    title: "React Digital Garden 04.2022 (Apr)",
    postId: "f9e47e2eb236",
  },
  {
    title: "React Digital Garden 03.2022 (Mar)",
    postId: "524729de8bda",
  },
  {
    title: "React Digital Garden 02.2022 (Feb)",
    postId: "181615cf1d6d",
  },
  {
    title: "React Digital Garden 01.2022 (Jan)",
    postId: "5719b53c54d2",
  },
]

const storiesNews2021 = [
  {
    title: "React Digital Garden 12.2021 (Dec)",
    postId: "88a827ea4486",
  },
  {
    title: "React Digital Garden 11.2021 (Nov)",
    postId: "ecd2a7533fa8",
  },
  {
    title: "React Digital Garden 10.2021 (Oct)",
    postId: "f19d4d307f3b",
  },
  {
    title: "React Digital Garden 09.2021 (Sep)",
    postId: "cb8a2480b7bd",
  },
]

const storiesGroup = [
  {
    title: "2023 Updates",
    storyPart: storiesNews2023,
  },
  {
    title: "2022 Updates",
    storyPart: storiesNews2022,
  },
  {
    title: "2021 Updates",
    storyPart: storiesNews2021,
  },
]

export const context = {
  stories: flatStoryGroup(storiesGroup),
  linkMap: LinkMap,
  title: "React Digital Garden Updates",
  path: "react-digital-garden-news",
}

const ReactDigitalGardenNewsPage = () => {
  return (
    <StoryGroup
      title={context.title}
      complex={true}
      stories={storiesGroup}
      linkMap={context.linkMap}
    />
  )
}

export default ReactDigitalGardenNewsPage
